import type { DropResult } from 'react-beautiful-dnd';

import {
  addPinnedFeed,
  moveRssItemToRssFolder,
  updatePinnedRssItemsOrder,
} from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/feed';
import {
  saveFilteredView,
  updateFilteredViewsOrder,
  updatePartialFilteredView,
} from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/filteredView';
import type { FilteredView, RssItem } from '../../../shared/types';
import makeLogger from '../../../shared/utils/makeLogger';
import reorder from '../../../shared/utils/reorder';

const logger = makeLogger(__filename);

export const pinnedFeedsDroppableId = 'pinned-feeds-list';
export const pinnedViewsdroppableId = 'pinned-views';
export const feedsSourceListDroppableId = 'feeds-source-list';
export const droppableFolderPrefix = 'droppable-folder-';
export const draggablePinnedFeedPrefix = 'pinned-feed-';
export const viewsListDroppableId = 'views-list';
export const draggableViewListItemPrefix = 'view-list-item-';
export const tagsListDroppableId = 'tags-list';
export const draggableTagListItemPrefix = 'tag-name-list-item-';

export const onDragEnd = ({
  result,
  pinnedRssItems,
  restOfPinnedViews,
}: { result: DropResult; pinnedRssItems: RssItem[]; restOfPinnedViews: FilteredView[] }) => {
  const { source, destination } = result;
  const sourceId = source.droppableId;
  const destinationId = destination?.droppableId;

  if (!destination) {
    return;
  }

  // Moving a pinned feed item into a folder
  if (sourceId === pinnedFeedsDroppableId && destinationId?.includes(droppableFolderPrefix)) {
    const rssFolderId = destinationId.replace(droppableFolderPrefix, '');
    const rssItemId = result.draggableId.replace(draggablePinnedFeedPrefix, '');

    moveRssItemToRssFolder({
      rssFolderId,
      rssItemId,
      options: { userInteraction: 'drag' },
    });

    return;
  }

  // Dropping a feed from the feeds management list into the pinned feeds sidebar
  // (it can be over a folder or just the list itself)
  if (
    sourceId === feedsSourceListDroppableId &&
    (destinationId === pinnedFeedsDroppableId || destinationId?.includes(droppableFolderPrefix))
  ) {
    let rssFolderId;

    if (destinationId?.includes(droppableFolderPrefix)) {
      rssFolderId = destinationId.replace(droppableFolderPrefix, '');
    }

    addPinnedFeed({
      rssSourceId: result.draggableId,
      order: destination.index,
      options: { userInteraction: 'click' },
      rssFolderId,
    });

    return;
  }

  // Dropping a view from the views management list into the pinned views sidebar
  if (sourceId === viewsListDroppableId && destinationId === pinnedViewsdroppableId) {
    const viewId = result.draggableId.replace(draggableViewListItemPrefix, '');

    updatePartialFilteredView(
      {
        id: viewId,
        isUnpinned: false,
        order: destination.index,
        showCountBadge: false,
      },
      { userInteraction: 'drag' },
    );

    return;
  }

  // Dropping a tag from the tags list into the pinned views sidebar
  if (sourceId === tagsListDroppableId && destinationId === pinnedViewsdroppableId) {
    const tagName = result.draggableId.replace(draggableTagListItemPrefix, '');
    const filter = {
      name: tagName,
      query: `tag:"${tagName}"`,
      order: destination.index,
      showCountBadge: false,
    };
    saveFilteredView(filter, window.location.pathname, { userInteraction: 'keypress' });
    return;
  }

  // Reordering pinned views or pinned feed items in the sidebar
  if (
    sourceId === destinationId &&
    (sourceId === pinnedFeedsDroppableId || sourceId === pinnedViewsdroppableId)
  ) {
    const startIndex = source.index;
    const endIndex = destination.index;
    const isReorderingPinnedRssItems = sourceId === pinnedFeedsDroppableId;

    if (isReorderingPinnedRssItems) {
      const reorderedViewsInArray = reorder<RssItem>(pinnedRssItems, startIndex, endIndex);

      updatePinnedRssItemsOrder(reorderedViewsInArray, { userInteraction: 'click' });
    } else {
      if (
        startIndex < 0 ||
        startIndex >= restOfPinnedViews.length ||
        endIndex < 0 ||
        endIndex > restOfPinnedViews.length
      ) {
        logger.error('Invalid start or end index when reordering pinned views', {
          restOfPinnedViewsLength: restOfPinnedViews.length,
          startIndex,
          endIndex,
        });
        return restOfPinnedViews;
      }

      const reorderedViewsInArray = reorder<FilteredView>(restOfPinnedViews, startIndex, endIndex);

      const viewsWithUpdatedOrderKey = reorderedViewsInArray.map((view, index) => ({
        id: view.id,
        order: index,
      }));
      updateFilteredViewsOrder(viewsWithUpdatedOrderKey, { userInteraction: 'click' });
    }
  }
};
